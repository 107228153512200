<template>
  <div class="py-4">
    <div v-if="$route.name == 'partner' && loaded">
      <router-view />
    </div>
    <div v-if="$route.name == 'partners' && loaded">
      <div class="mb-6 flex flex-row justify-start items-center">
        <BaseButton
          naked
          label="add partner"
          :progress="progressCreate"
          @click="create()"
        />
      </div>

      <BaseTable :table="table" :loading="!loaded" />

    </div>
  </div>
</template>

<script>
import mixinGlobal from "@/mixins/mixinGlobal"

export default {
  name: "Partners",
  mixins: [mixinGlobal],
  components: {},
  data() {
    return {
      progressCreate: false,
    }
  },
  computed: {
    partners() {
      return this.$store.state.partners.partners
    },
    loaded() {
      return (
        this.$store.state.partners.loadingState.partners == "loaded" &&
        this.$store.state.exchangeRates.loadingState.exchangeRates == "loaded"
      )
    },
    totalPartners() {
      return this.$store.getters['partners/totalPartners']
    },
    totalDonationsCount() {
      return this.$store.getters['partners/totalDonationsCount']
    },
    totalETH() {
      return this.$store.getters['partners/totalETH']
    },
    totalUSD() {
      return this.$store.getters['partners/totalUSD']
    },
    table() {
      let t = {
        head: [
          [
            {c: "", align: 'left'},
            {c: "name", align: 'left'},
            {c: "eth address"},
            {c: "donations"},
            {c: "total eth"},
            {c: "total usd"},
          ],
        ],
        body: [],
        foot: [
          [
            {c: ""},
            {c: this.totalPartners},
            {c: ""},
            {c: this.totalDonationsCount},
            {c: this.totalETH},
            {c: this.totalUSD.toLocaleString()},
          ],
        ],
      }

      this.partners.map(p => {
        t.body.push([
          {c: null, warning: this.partnerHasWarning(p)},
          {c: p.name, ignored: p.demo, routerLink: {name: 'partner', params: {partnerId: p.id}}},
          {c: p.ethAddress, truncate: true, mono: true, clickToCopy: true, ignored: p.demo},
          {c: p.totalDonationsCount ? p.totalDonationsCount : 0, ignored: p.demo},
          {c: this.roundAmount(p.totalDonations.ETH), ignored: p.demo},
          {c: this.convert(p.totalDonations.ETH, 'ETH', "USD", 0).toLocaleString(), ignored: p.demo}
        ])
      })

      return t
    },
  },
  async created() {
    await Promise.all([this.$store.dispatch("partners/bind")])
  },
  methods: {
    async create() {
      this.progressCreate = true
      let partnerId = await this.$store.dispatch("partners/create")
      this.progressCreate = false
      this.$router.push({
        name: "partner",
        params: {
          partnerId,
          mode: "edit",
        },
      })
    },
    partnerHasWarning(partner) {
      // demo partner is always without warning
      if (partner.id == 'demoPartner') return false

      let warning = false
      // missing name
      if (!partner.name || partner.name.length < 2) warning = true
      // missing ETH address
      if (!partner.ethAddress) warning = true
      // missing website
      if (!partner.websiteURL) warning = true
      // missing country
      if (!partner.country) warning = true
      // missing kind
      if (!partner.kind) warning = true
      // missing logo
      if (!partner.logos || partner.logos.length < 1) warning = true
      
      return warning
    }
  },
}
</script>

<style lang="less" scoped>
.partner-eth-address {
  cursor: copy;
}
</style>
